.moderation {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 500px;
    margin-bottom: 20px;
  }

  &__ajax-form {
    display: none;
  }

  &__title {
    font-style: italic;
    font-weight: 500;
    font-size: 54px;
    line-height: 110%;
    color: white;
    margin-bottom: 20px;
  }

  &__item {
    padding: 30px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__user-phone {
    color: white;
  }

  &__user-label {
    font-size: 12px;
    color: white;
    margin-bottom: 5px;
}

  &__status-wrapper {
    display: flex;
    align-items: center;
  }

  &__form {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.success {
      transition: var(--default-transition);
      .moderation {

        &__image {
          opacity: 0.2;
        }

        &__container {
          opacity: 0.2;
        }

        &__ajax-form {
          transition: var(--default-transition);
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          font-weight: bold;
          font-style: italic;
          white-space: nowrap;
          border-radius: 10px;
          padding: 50px;
          opacity: 1;
        }

        &__success-title {
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }

  }

  &__image {
    object-fit: cover;
    width: 400px;
    height: 100%;
    border-radius: 4px;
    margin-right: 20px;
  }

  &__field {
    margin: 10px 20px 10px 0;

    select {
      height: 100%;
    }

    label {
      color: white;
    }

    input[type="text"], input[type="search"],
    input[type="password"], select {
      padding: 15px 10px;
    }

    .hint {
      color: white;
    }
  }

  &__button {
    max-width: 176px;
    margin-top: 10px;
    background-color: var(--red-color);
    padding: 18px 24px;
    text-align: center;
    max-height: 53px;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 13px;
    line-height: 133%;
  }
}

@include respond-down('medium') {
  .moderation {

    &__title {
      margin-bottom: 0;
      font-size: 20px;
    }

    &__form {
      flex-direction: column;
    }

    &__image {
      width: 100%;
    }
  }
}