.lottery-page {

  &__inner {
    position: relative;
    height: 100vh;
    display: flex;
    padding-top: 10vh;
    justify-content: flex-end;
  }

  &__button {
    position: absolute;
    left: 0;
    bottom: 10%;
    background-color: var(--red-color);
    padding: 18px 24px;
    border-radius: 3px;
    display: flex;
    max-width: 244px;
    font-weight: 900;
    font-size: 13px;
    line-height: 133%;
    color: white;
    text-transform: uppercase;
  }

  &__list {
    &:last-child{
      padding-bottom: 100px;
      overflow-y: scroll;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 50px 200px 310px 200px;
    color: white;
    padding: 20px;
    border-bottom: 1px solid white;



    &_header {
      font-weight: bold;

    }
  }

  &__switch {
      width: 170px;
      position: absolute;
      display: flex;
      justify-content: space-between;
  }

  &__switch-link {
    text-transform: uppercase;
    color: white;
    font-style: italic;
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;

  }
  &__switch-tab{
    &._active {
      border-bottom: 3px solid var(--red-color);
    }
  }
  &__field {
  }
  &__logo{
    position: absolute;
    left: 10%;
    top: 10%;
  }
}