.profile-auth {
  &__container {

  }

  &__header {

  }

  &__form {

  }

  &__title {
    font-style: italic;
    font-weight: 900;
    line-height: 110%;
  }

  &__description {
    font-style: italic;
    font-weight: 900;
    line-height: 150%;
  }

  &__fields {
    border-bottom: 1px solid black;
    min-width: 270px;
    margin-bottom: 20px;
  }

  &__action {

  }

  &__button {
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 13px;
    line-height: 133%;
  }
}

@include respond-up('large') {
  .profile-auth {
    &__container {
      height: 100vh;

    }

    &__header {

    }

    &__form {
      max-width: 700px;
      margin: 0 auto;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }

    &__title {
      font-size: 40px;
      margin-bottom: 30px;
    }

    &__description {
      margin-bottom: 20px;
    }

    &__fields {

    }

    &__action {

    }
  }
}

@include respond-down('medium') {
  .profile-auth {
    &__container {

    }

    &__header {

    }

    &__form {

    }

    &__title {

    }

    &__description {

    }

    &__fields {

    }

    &__action {

    }
  }
}