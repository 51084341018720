.winner {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-style: normal;
    font-weight: 300;
    font-size: 35px;
    line-height: 110%;
    text-transform: uppercase;
  }

  &__subtitle {
    text-align: center;
    line-height: 150%;

  }

  &__list-title {
    white-space: nowrap;
    line-height: 16px;
    color: var(--grey-text-light);
  }

  &__list-head {
  }

  &__cup {
    margin-bottom: 10px;
  }

  &__list-subtitle {
    line-height: 16px;
  }
}

@include respond-up('large') {
  .winner {
    padding: 46px 100px 63px;

    &__title {
      margin-bottom: 30px;
      font-size: 40px;
    }

    &__subtitle {
      margin-bottom: 50px;
      font-size: 17px;
      max-width: 604px;
      color: var(--grey-light-color);
    }

    &__list {
      width: 100%;
    }

    &__list-head {
      margin: 0 -10px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
    }

    &__list-title {
      padding: 0 10px;
      font-size: 14px;
      flex: 0 0 33.333%;

      &:nth-child(2) {
        white-space: nowrap;
      }
    }

    &__list-body {
      margin: 0 -10px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid var(--grey-border);

      &:last-child {
        border-bottom: 1px solid var(--grey-border);
      }
    }

    &__list-subtitle {
      font-size: 14px;
      padding: 17px 10px 20px;
      flex: 0 0 33.333%;

      &:nth-child(2) {
        white-space: nowrap;

      }

    }

    &__list-mobile {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .winner {
    padding: 25px 50px;

    &__title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-size: 15px;
      margin-bottom: 31px;


    }

    &__list-mobile {
      width: 100%;
    }

    &__list-container {
      display: flex;
      padding: 11px 0;
      border-top: 1px solid var(--grey-border);

      &:last-child {
        border-bottom: 1px solid var(--grey-border);
      }
    }

    &__list-wrapper {
      //flex: 0 0 50%;
      display: flex;
      flex-direction: column;
    }

    &__list {
      display: none;
    }

    &__list {
    }

    &__list-head {
    }

    &__list-body {
    }

    &__list-subtitle {
      padding-bottom: 7px;
      font-size: 14px;
      &_grey {
        color: var(--grey-light-color);
      }
    }
  }
}