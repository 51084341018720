.rules {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-style: italic;
    font-weight: 900;
    line-height: 110%;
  }

  &__description {

  }
}

@include respond-up('large') {
  .rules {
    padding: 46px 100px 90px;
    max-height: 700px;
    max-width: 850px;
    overflow-y: scroll;
    &__title {
      font-size: 40px;
      margin-bottom: 30px;

    }

    &__description {

    }
  }
}

@include respond-down('medium') {
  .rules {
    padding: 26px 16px 37px;
    max-height: 540px;
    overflow-y: scroll;
    &__title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &__description {

    }
  }
}

@include respond-down('small') {
  .rules {
    max-height: 490px;
  }
}