.page {
  background: #000D1F url('/static/images/base/bg.png') no-repeat;
  background-size: cover;
  position: relative;

  &__container {
    position: relative;
    z-index: 10;
  }

  &__title {
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    color: #fff;
    text-transform: uppercase;
  }

  &__title-strong {
    font-style: normal;
    font-weight: bold;
    line-height: 110%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    display: block;
  }

  &__conten-block {

  }

  &__image-container {

  }

  &__image {
  }

  &__conten {
    display: flex;
    flex-direction: column;

  }

  &__description {
    font-style: normal;
    font-weight: normal;
    color: #fff;
    line-height: 150%;
  }

  &__button-wrapper {

  }

  &__button {
    background-color: var(--red-color);
    padding: 18px 24px;
    display: flex;
    max-width: fit-content;
    border-radius: 83px;
    align-items: center;
  }

  &__svg {
    margin-right: 19px;
    display: flex;
  }

  &__button-text {
    font-weight: 900;
    font-size: 13px;
    line-height: 133%;
    color: white;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
  }

  &__item {

  }

  &__wrapper {

  }

  &__count {
    color: var(--red-color);
    font-weight: 900;
    font-size: 20px;
    line-height: 130%;

  }

  &__list-title {
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;
    color: white;
    text-transform: uppercase;
  }

  &__list-description {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    line-height: 150%;
  }

  &__timer-mobile {

  }

  &__info {
    border-radius: 10px;
    border: 1px solid white;
    padding: 20px;
    color: white;
    font-size: 15px;
    line-height: 1.5em;
    z-index: 1000;
  }
  &__info-link {
    border-bottom: 1px solid white;
  }
}

@include respond-up('x-large'){
  .page{
    height: calc(var(--vh, 1vh) * 100);
    &__image-container{
      display: none;
    }

    &__image-container-xlarge{
      display: block;
      position: fixed;
      left: 0;
      bottom: -10px;


      &:before {
        z-index: -1;
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        bottom: 0px;
        left: 0;
        border-style: solid;
        border-width: calc(575 / 1600 * 100vw) 0 0 calc(790 / 1600 * 100vw);
        border-color: transparent transparent transparent #f26422;
      }
    }

    &__image{
      width: calc(575 / 1920 * 100vw);
    }

    &__title {
      padding-left: 220px;
      font-size: calc((35 / 1920) * 100vw);
      margin-bottom: 27px;
      padding-top: 40px;
    }

    &__title-strong {
      padding-top: 10px;
      font-size: calc((55 / 1920) * 100vw);
      margin-left: 55px;
      margin-top: 22px;
    }

    &__conten-block {
      width: 60%;
      float: right;
    }

    &__conten {
        flex: 0 0 50%;
    }

    &__description {
      font-size: calc(16 / 1920 * 100vw);
      max-width: 900px;
      margin-bottom: 34px;
    }

    &__button-wrapper{
      margin-bottom: 36px;
    }

    &__button {
      display: flex;
    }

    &__svg {

    }

    &__list {
      margin: 0 -25px;
    }

    &__item {
      padding: 0 25px;
      position: relative;

      &:last-child {
        .page__count {
          &:before {
            bottom: 20px;
            width: 28px;
            height: 28px;
            background: {
              image: url('../svg/circle-check.svg');
              size: cover;
            };
          }
        }

        &:after {
          display: none;
        }
      }

      &:after {
        position: absolute;
        content: '';
        top: 43px;
        left: 30px;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted var(--red-color);
      }
    }

    &__count {
      padding-bottom: 52px;
      position: relative;

      &:before {
        bottom: 25px;
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        background: {
          image: url('../svg/circle.svg');
        };
      }
    }

    &__list-title {
      max-width: 140px;
      margin-bottom: 10px;
    }

    &__list-description {
      max-width: 165px;
    }

    &__info {
      max-width: 600px;
    }
  }
}

@include respond('large') {
  .page {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;

    &__title {
      padding-left: 220px;
      font-size: calc((35 / 1600) * 100vw);
      margin-bottom: 27px;
      padding-top: 40px;
    }

    &__title-strong {
      padding-top: 10px;
      font-size: calc((55 / 1600) * 100vw);
      margin-left: 55px;
      margin-top: 22px;
    }

    &__conten-block {
      display: flex;
      justify-content: space-between;
      height: 100vw;
    }

    &__image-container-xlarge{
      display: none;
    }

    &__image-container {
      z-index: 2;
      position: relative;
      height: 100%;
      max-height: 570px;
      flex: 0 0 40%;

      &:before {
        z-index: -1;
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        bottom: 0;
        left: 0;
        border-style: solid;
        border-width: calc(575 / 1600 * 100vw) 0 0 calc(790 / 1600 * 100vw);
        border-color: transparent transparent transparent #f26422;

      }
    }

    &__image {
      object-position: bottom;
      height: 100%;
      object-fit: contain;
    }

    &__conten {
      flex: 0 0 50%;
    }

    &__description {
      font-size: 16px;
      max-width: 553px;
      margin-bottom: 34px;
    }

    &__button-wrapper{
      margin-bottom: 36px;
    }

    &__button {
      display: flex;
    }

    &__svg {

    }

    &__list {
      margin: 0 -25px;
    }

    &__item {
      padding: 0 25px;
      position: relative;

      &:last-child {
        .page__count {
          &:before {
            bottom: 20px;
            width: 28px;
            height: 28px;
            background: {
              image: url('../svg/circle-check.svg');
              size: cover;
            };
          }
        }

        &:after {
          display: none;
        }
      }

      &:after {
        position: absolute;
        content: '';
        top: 43px;
        left: 30px;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted var(--red-color);
      }
    }

    &__count {
      padding-bottom: 52px;
      position: relative;

      &:before {
        bottom: 25px;
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        background: {
          image: url('../svg/circle.svg');
        };
      }
    }

    &__list-title {
      max-width: 140px;
      margin-bottom: 10px;
    }

    &__list-description {
      max-width: 165px;
    }

    &__info {
      max-width: 600px;
    }
  }
}

@include respond('medium') {
  .page {
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      bottom: 0;
      left: 0;
      border-style: solid;
      border-width: 440px 0 0 640px;
      border-color: transparent transparent transparent #f26422;
    }

    &:after{
        position: absolute;
        content: '';
        z-index: 100;
        width: 536px;
        height: 393px;
        bottom: -20px;
        left: 0;
        background: {
          image: url('/static/images/base/tyres-mobile.png');
          repeat: no-repeat;
          size: contain;
        };
    }

    &__container {
      margin-top: 39px;
    }

    &__conten {
      margin: 0 105px;
    }

    &__title {
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
      line-height: 110%;
      margin-left: 39px;
      margin-bottom: 24px;
    }

    &__title-strong {
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 110%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      margin-top: 24px;
      margin-left: 66px;
    }

    &__image-container {
      display: none;
    }

    &__description {
      //padding: 0 16px 0 40px;
      font-size: 15px;
      margin-bottom: 25px;
    }

    &__button-wrapper {
      margin-bottom: 40px;
      //padding: 0 16px 260px 40px;
      //position: relative;
      //
      //&:before {
      //  position: absolute;
      //  content: '';
      //  z-index: 1;
      //  width: 324px;
      //  height: 287px;
      //  bottom: -65px;
      //  left: 0;
      //  background: {
      //    image: url('/static/images/base/tyres-mobile.png');
      //    repeat: no-repeat;
      //    size: contain;
      //  };
      //}
    }

    &__list {
      margin: 0 -25px;
      padding-bottom: 400px;
    }

    &__item {
      padding: 0 25px;
      position: relative;

      &:last-child {
        .page__count {
          &:before {
            bottom: 20px;
            width: 28px;
            height: 28px;
            background: {
              image: url('../svg/circle-check.svg');
              size: cover;
            };
          }
        }

        &:after {
          display: none;
        }
      }

      &:after {
        position: absolute;
        content: '';
        top: 43px;
        left: 30px;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted var(--red-color);
      }
    }

    &__count {
      padding-bottom: 52px;
      position: relative;

      &:before {
        bottom: 25px;
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        background: {
          image: url('../svg/circle.svg');
        };
      }
    }

    &__list-title {
      max-width: 140px;
      margin-bottom: 10px;
    }

    &__list-description {
      max-width: 165px;
    }

    &__info {
      max-width: 600px;
    }
  }
}

@include respond-down('medium'){
  .page{
    &__image-container-xlarge{
      display: none;
    }
  }
}

  @include respond-down('small'){
    .page{
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center;

      &:before{
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 515px 0 0 820px;
        border-color: transparent transparent transparent #f26422;
        bottom: 0;
        left: 0;
      }

      &__container {

      }

      &__title {
        padding: 38px 20px 15px;
        display: inline-block;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 110%;
      }

      &__title-strong {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 110%;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-top: 14px;
      }

      &__image-container{
        display: none;
      }

      &__description {
        padding: 0 20px 26px;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
      }

      &__button-wrapper {
        padding: 0 0 260px 20px;
        position: relative;

        &:before {
          position: absolute;
          content: '';
          z-index: 1;
          width: 324px;
          height: 287px;
          bottom: -65px;
          left: 0;
          background: {
            image: url('/static/images/base/tyres-mobile.png');
            repeat: no-repeat;
            size: contain;
          };
        }
      }

      &__list {
        z-index: 10;
        position: relative;
        overflow: scroll;
        padding-left: 15px;
        padding-bottom: 35px;
      }

      &__item {
        padding: 0 5px;
      }

      &__wrapper {
        background-color: white;
        padding: 10px 12px;
        height: 100%;
        box-shadow: 0 30px 30px -17px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
      }

      &__count {
        margin-bottom: 4px;
      }

      &__list-title {
        color: black;
      }

      &__list-description {
        color: var(--grey-text-color);
        opacity: 0.6;
        min-width: 145px;
      }

      &__info {
        margin: 0 30px 30px;
      }
    }
  }
