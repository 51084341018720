.recall-form {

  &__title {
    text-transform: uppercase;
    font-weight: 900;
    line-height: 150%;
    font-style: italic;
    text-align: center;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    input[type="text"] {
      border: none;
      --default-input-height: 40px;
      border-bottom: 2px solid var(--border-grey);
    }

    textarea {
      resize: none;
      border: none;
      border-bottom: 2px solid var(--border-grey);
    }
  }


  &__button {
    border-radius: 84px;
    background-color: var(--red-color);
    color: white;
    padding: 18px 0;
    width: 100%;
  }

  &__policy {
    color: var(--grey-light-color);

  }

  &__success-icon {
    margin-bottom: 20px;
  }

  &__policy-link {
    color: var(--red-color);
  }

  &__success-title {
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 7px;
    font-style: italic;
    display: inline-block;
  }

  &__success-description {
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    max-width: 230px;
    color: var(--grey-light-color);
  }
}

@include respond-up('large') {
  .recall-form {
    padding: 34px 55px 50px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 18px;
      margin-bottom: 46px;
    }

    &__input {
      margin-bottom: 30px;

    }

    &__button {
      margin-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {

    &__form {
      overflow: hidden;
    }

    &__data {
      padding: 23px 24px 29px;
    }

    &__info {

    }

    &__title {
      font-size: 16px;
      margin-bottom: 33px;
    }

    &__inputs {

    }

    &__input {
      margin-bottom: 30px;
    }

    &__button-container {
    }

    &__button {
      margin-bottom: 16px;
    }

    &__policy {

    }

    &__policy-link {

    }

    &__success {

    }
  }
}