.user-page {
  &__wide {
    width: 100% !important;
  }

  &__tabs {
    display: flex;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      border-bottom: 1px solid #E7E7E7;
      width: calc(100% - -0px);
      left: 0;
    }
  }

  &__tab {
    z-index: 10;
    font-weight: 900;
    letter-spacing: 0.03em;
    color: #16171A;
    text-transform: uppercase;

    &._active {
      color: #D62F1F;
      border-bottom: 4px solid #D62F1F;
    }
  }

  &__term-link {
    color: var(--red-color);
    text-align: center;
  }

  &__contents {

  }

  &__content {

  }

  &__login-form {

  }

  &__data {

  }

  &__inputs {

  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    input[type="text"], input[type="password"] {
      border: none;
      --default-input-height: 40px;
      border-bottom: 2px solid var(--border-grey);
      min-width: 295px;

      &:focus {
        border-bottom: 2px solid #16171A;
      }
    }

    textarea {
      resize: none;
      border: none;
      border-bottom: 2px solid var(--border-grey);
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    background-color: var(--red-color);
    color: white;
    padding: 18px 0;
    width: 100%;
    box-shadow: 0px 20px 30px -15px rgba(214, 47, 31, 0.5);
  }

  &__reg-button {
    max-width: 365px;
  }

  &__register-form {

  }

  &__register-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 64px;
  }

  &__register-input {

  }

  &__policy-link {
    color: var(--red-color);
  }

  &__terms {
    font-size: 14px;
    line-height: 21/14*1em;
    color: #7A7A7A;
    text-align: center;
  }

  &__reg-info {
    display: block;
    font-size: 17px;
    line-height: 25/17*1em;
    color: #7A7A7A;
    text-align: center;
    padding-top: 41px;
  }

  &__pas-hint {
    font-size: 12px;
    line-height: 18/12*1em;
    color: #A5A5A5;
    display: flex;
    flex-wrap: wrap;
    max-width: 166px;
  }
}

@include respond-up('large') {
  .user-page {
    width: fit-content;

    &__tabs {
      padding: 0 100px;
    }

    &__tab {
      font-size: 18px;
      line-height: 27/18*1em;

      &:first-child {
        margin-right: 29px;
      }
    }

    &__link {
      padding: 30px 21px 26px;
    }

    &__contents {

    }

    &__login-content {
      padding: 62px 100px 40px;
    }

    &__reg-content {
    }

    &__register-form {
      padding: 58px 100px 40px;
    }

    &__button {
      margin-bottom: 26px;
    }

    &__term-link {
    }

    &__input {
      margin-bottom: 40px;
    }

    &__register-input {

    }

    &__button-container {

    }

    &__terms {
      max-width: 348px;
    }
  }
}

@include respond-down('medium') {
  .user-page {
    width: 343px;

    &__tab {
      font-size: 16px;
      line-height: 24/16*1em;
    }

    &__tabs {
      padding: 0 30px;
    }

    &__link {
      padding: 13px 27px;
    }

    &__contents {
    }

    &__login-content {
      padding: 36px 24px 30px;
    }

    &__register-form {
      padding: 36px 24px 30px;
    }

    &__register-inputs {
      display: flex;
      flex-direction: column;
    }

    &__register-input {
      &:first-child {
        order: 1;
      }

      &:nth-child(2) {
        order: 4;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(4) {
        order: 5;
      }

      &:nth-child(5) {
        order: 3;
      }
    }

    &__input {
      margin-bottom: 30px;
    }

    &__button {
      margin-bottom: 16px;
    }

    &__terms {
      font-size: 12px;
      line-height: 18/12*1em;
    }

    &__reg-info {
      font-size: 15px;
      line-height: 22/15*1em;
      padding: 18px 45px 0;
    }

    &__pas-hint {
      max-width: none;
      margin-bottom: 26px;
      order: 6;
    }
  }
}