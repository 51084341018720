.prize {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    text-transform: uppercase;
    font-style: italic;
    font-weight: 900;
    line-height: 110%;
  }

  &__image-block {
    display: flex;
    justify-content: space-between;
  }

  &__image-wrapper {
    background-color: var(--dark-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &_tyres, &_man {
      overflow: hidden;

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 273px 0 0 320px;
        border-color: transparent transparent transparent #f26422;
      }
    }
  }

  &__image-title {
    font-style: italic;
    font-weight: 900;
    line-height: 150%;
    color: white;
    text-transform: uppercase;
  }

  &__image {
    object-fit: contain;

    &_tyres {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 10;
    }

    &_man {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 10;
    }

  }

  &__content-container {

  }

  &__content-text {

  }
}

@include respond-up('large') {
  .prize {
    padding: 46px 100px 70px;
    max-width: 850px;


    &__title {
      font-size: 40px;
      margin-bottom: 41px;
    }

    &__image-block {
      width: 100%;
      margin-bottom: 40px;
    }

    &__image-wrapper {
      width: 309px;
      height: 382px;

    }

    &__image-title {
      margin-top: 24px;
      font-size: 17px;
      flex-grow: 1;
      max-width: 180px;
      text-align: center;

    }

    &__image {

      &_tyres {
        width: 261px;
        height: 232px;
      }

    }

    &__content-container {

    }

    &__content-text {

    }
  }
}

@include respond-down('medium') {
  .prize {
    padding: 26px 17px 35px;

    &__title {
      margin-bottom: 22px;
    }

    &__image-block {
      width: 100%;
      margin-bottom: 30px;
    }

    &__image-wrapper {
      width: calc((149/375) * 100vw);
      height: calc((228/375) * 100vw);


      &_tyres, &_man {

        &:before {
          bottom: -45%;
        }
      }
    }

    &__image-title {
      text-align: center;
      max-width: calc((100/375) * 100vw);
      font-size: 12px;
      margin-top: 17px;
    }

    &__image {

      &_tyres {
        width: calc((129/375) * 100vw);
        height: calc((115/375) * 100vw);
      }

      &_man {
        bottom: -10px;
        left: -10px;
        width: calc((118/375) * 100vw);
        height: calc((159/375) * 100vw);
      }
    }

    &__content-container {

    }

    &__content-text {

    }
  }
}