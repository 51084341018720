.profile-form {
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21/18*1em;
    color: white;
  }

  &__field {
    .floating-field {
      textarea {
        padding-top: 10px;
      }
      label {
        background-color: var(--t);
      }

      input[type='text'],
      input[type='password'] {
        color: white;
        background: none;
        border-bottom: 2px solid rgba(255, 255, 255, 0.4);
        transition: border-bottom-color .4s ease-in-out;

        &:focus {
          border-bottom-color: white;
        }

        &::placeholder {
          color: var(--t);
        }

        & + label {
          color: var(--gray-text-color);
        }
      }
    }
  }

  &__button {
    width: 100%;
    padding: 18px;
    border-radius: 3px;
    background-color: white;
    font-weight: 900;
    font-size: 13px;
    line-height: 1.35em;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--dark-text-color);
  }
}

@include respond-up('large') {
  .profile-form {
    margin-top: 30px;

    &__block {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 60px;
      }
    }

    &__title {
      flex: 0 0 150px;
      margin-right: 80px;
    }

    &__fields {
      flex: 1;
    }

    &__field {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &__button {
      margin-top: 12px;
    }
  }
}

@include respond-down('medium') {
  .profile-form {
    padding: 16px;

    &__block {
      &:not(:last-child) {
        margin-bottom: 36px;
      }
    }

    &__title {
      margin-bottom: 20px;
    }

    &__field {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &__button {
      margin-top: 16px;
    }
  }
}