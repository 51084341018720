.check {
  &__form {

  }

  &__content {

  }

  &__empty {
    font-size: 16px;
    line-height: 19px;
    color: white;
  }

  &__question {
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    color: white;
    background-color: rgba(255, 255, 255, 0.08);
    padding: 18px 23px;
  }

  &__wrapper {
  }

  &__inner {
    display: grid;
    grid-template-columns: 100px 1fr 1fr 1fr;
    grid-column-gap: 20px;
    justify-content: space-between;
    align-items: center;
  }

  &__image {
    width: 56px;
    height: 79px;
    object-fit: contain;
  }

  &__image-wrapper {
    flex: 0 0 10%;
  }

  &__status-green {
    border-radius: 100px;
    background-color: var(--green-color);
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }

  &__status-wrapper {
    display: flex;
    max-height: 20px;
    padding-right: 20px;

    &_green {
      max-height: 30px;
    }

  }

  &__status-svg {
    margin-right: 10px;
    position: relative;

    &_green {
      max-height: 19px;
    }

    &:hover {
      .check__status-why {
        bottom: -35px;
        white-space: nowrap;
        display: block;
        left: -50%;
        transform: translate(-50%);
        position: absolute;
        color: white;
        font-size: 13px;
        line-height: 120%;
        background-color: black;
        padding: 4px 6px;
      }
    }
  }

  &__date {
    font-size: 16px;
    line-height: 19px;
    color: white;
    flex: 0 0 30%;
  }

  &__user{
    font-size: 16px;
    line-height: 19px;
    color: white;
  }

  &__username{
    margin-bottom: 10px;
  }

  &__status {
    color: white;
    font-size: 16px;
    line-height: 19px;

    &_spent {
      margin-right: 7px;
    }
  }

  &__status-why {
    display: none;
  }
}

@include respond-up('large') {
  .check {

    &__empty {
      margin-bottom: 36px;
    }

    &__wrapper {
      margin-bottom: 36px;
      max-height: 500px;
      overflow-y: scroll;
    }

    &__inner {
      padding: 20px 0 19px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

@include respond-down('medium') {
  .check {
    padding: 0 16px;
    &__form {

    }

    &__content {

    }

    &__empty {
      margin-bottom: 27px;
    }

    &__question {
    }

    &__wrapper {
      margin-bottom: 20px;
    }

    &__inner {
      padding: 20px 0 19px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      &:first-child {
        padding-top: 0;
      }
    }

    &__inner {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 50px 2fr;
      grid-column-gap: 20px;
    }

    &__image-wrapper {
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &__date {

    }

    &__status {

    }
  }
}