.timer {
  position: relative;
  display: flex;
  align-items: center;
  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    max-width: 110px;
    color: #fff;

  }

  &__countdown {
    display: flex;
    margin: 0px -2px;
  }

  &__countdown-number {
    align-items: center;
    padding: 0 2px;
    display: flex;
    flex-direction: column;
  }

  &__countdown-time {
    margin-bottom: 5px;
    color: white;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    padding: 7px 5px;
    border-radius: 3px;
    background-color: var(--dark-color);

  }

  &__countdown-text {
    color: white;
    font-size: 12px;
    line-height: 14px;
  }
}

@include respond-up('large') {
  .timer {
    &__title {
      font-size: 15px;
      margin-right: 22px;
    }
  }
}
@include respond('medium'){
  .timer{
    &__countdown {
      margin-bottom: -15px;
    }
  }
}

@include respond-down('medium') {
  .timer {
    //align-items: normal;
    &__title {
      margin-right: 22px;
      max-width: 90px;

    }

    &__countdown {

    }

    &__countdown-number {

    }

    &__countdown-time {
      background-color: var(--grey-color-another);
    }

    &__countdown-text {

    }
  }
}