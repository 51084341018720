.faq {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__header {
    .header__timer {
      display: none;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    color: white;
  }

  &__recall-title {
    font-weight: 700;
    font-size: 35px;
    line-height: 1.1;
  }

  &__list {
    &::-webkit-scrollbar {
      display: none;
    }

    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__item {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    &._opened {
      .faq {
        &__item-tab-name {
          margin-bottom: 30px;
        }

        &__item-tab-icon {
          transform: rotate(-90deg);
        }

        &__item-tab-icon-minus {
          opacity: 0;
        }
      }
    }

    border-bottom: 1px solid var(--border-footer);
  }

  &__item-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-tab-name {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    transition: var(--default-transition);
  }

  &__item-tab-icon {
    transition: var(--default-transition);
    position: relative;
    height: 15px;
    width: 15px;
  }

  &__item-tab-icon-plus {
    position: absolute;
    left: 6px;
    width: 3px;
    height: 15px;
    background-color: var(--red-color);
  }

  &__item-tab-icon-minus {
    position: absolute;
    height: 3px;
    width: 15px;
    top: 6px;
    background-color: var(--red-color);
    opacity: 1;
    transition: var(--default-transition);
  }

  &__item-content-container {
    overflow: hidden;
    transition: var(--default-transition);
    height: 0;
  }

  &__item-content {
    color: white;
    font-size: 17px;
    line-height: 1.5;
  }

  &__form-container {
    max-height: 491px;
    background-color: white;
  }
}

@include respond-up('large') {
  .faq {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    &__container {
      flex: 0 0 520px;
      display: flex;
    }

    &__head {
      margin-bottom: 40px;
    }

    &__form-container {
      flex: 0 0 385px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__block {
      margin: 0 60px;
      display: flex;
      justify-content: space-between;
    }

    &__list {
      max-height: 500px;
    }

    &__item {
      padding: 26px 0;
      border-bottom: 1px solid ;
    }

    &__item-tab-icon {
      margin-right: 20px;
    }

    &__item-content {
      padding: 0 0 0 35px;
      font-size: 16px;
      line-height: 19px;
    }
  }
}


@include respond-down('medium') {
  .faq {
    padding-bottom: 16px;
    background-size: cover;
    background-position: center;

    &__block {
      padding-top: 32px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 15px;
      display: inline-block;
    }

    &__list {
      margin-bottom: 36px;
    }

    &__item {
      padding: 19px 0;
    }

    &__item-tab-name {
      font-size: 17px;
    }

    &__item-tab-icon {
      margin-right: 20px;
    }

    &__item-content {
      padding-left: 35px;
      font-size: 16px;
    }
  }
}