.profile-main {
  background-image: url('/static/images/base/bg.png');
  background-size: cover;
  background-repeat: no-repeat;

  &__head {
    .header__timer {
      display: none;
    }
  }

  &__list {
    display: flex;
  }

  &__item {
    padding: 0 30px 23px;

    &:first-child {
      display: none;
    }

    &._active {
      border-bottom: 3px solid var(--red-color);

      .profile-main__link {
        color: var(--red-color);
      }
    }
  }

  &__logout {
    color: rgba(255, 255, 255, 0.4);
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
  }

  &__nav-head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  &__link {
    text-transform: uppercase;
    color: white;
    font-style: italic;
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;

    &._active {
      color: var(--red-color);
    }
  }

  &__logout-text {
    margin-top: 3px;
    margin-right: 10px;
  }
}

@include respond-up('large') {
  .profile-main {
    height: 100vh;

    &__head {
      margin-bottom: 42px;
    }

    &__nav-head {
      margin-bottom: 20px;
    }

    &__new-check {
      flex: 0 0 412px;
      margin-right: 66px;
    }

    &__wrapper {
      margin: 0 120px 0 100px;
      display: flex;
    }

    &__logout {
      &_mobile {
        display: none;
      }
    }

    &__inner {
      justify-content: space-between;
      min-width: 720px;
    }
  }
}

@include respond-down('medium') {
  .profile-main {
    //height: 100vh;

    &__nav-head {
      margin-bottom: 10px;
    }

    &__new-check {
      display: none;
    }

    &__item {
      padding: 0 12px 13px;

      &:first-child {
        display: block;
      }

      &._active {
        border-bottom: 3px solid var(--red-color);

        .profile-main__link {
          color: var(--red-color);
        }
      }
    }

    &__list {
      overflow: scroll;
      white-space: nowrap;
    }

    &__logout {
      display: none;

      &_mobile {
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
        margin-bottom: 16px;
      }
    }

    &__link {
      font-size: 16px;
    }
  }
}