.photos-input {
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px dashed var(--border-color);
    border-radius: 3px;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    height: 15px;
    width: 15px;
  }

  &__title {
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 1.5em;
    text-align: center;
    color: var(--default-text-color);
  }

  &__text {
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.5em;
    text-align: center;
    color: var(--gray-text-color);
  }

  &__files {
    .files-list {
      margin-top: 14px;

      &._hide {
        display: none;
      }
    }
  }

  &__drop {
    position: relative;
  }

  &__errors {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    font-size: 12px;
    color: var(--alert-color);
    text-align: center;
    opacity: 0;
    transition: opacity .4s ease-in-out;
    pointer-events: none;
  }

  &__values {
    display: none;
  }
}