.recovering {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 400px;
  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: 0.03em;
    color: black;
    text-transform: uppercase;
    padding-bottom: 28px;
  }

  &__form {
    padding: 20px;
  }

  &__subtitle {
    font-size: 17px;
    line-height: 1.5em;
    text-align: center;
  }

  &__fields {
    margin-bottom: 20px;
  }
}

.callback {

  &__success {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  &__success-title {
    text-transform: uppercase;
    font-style: italic;
    font-weight: 900;
    line-height: 110%;
    margin-bottom: 20px;
    font-size: 24px;
  }

  &__success-description {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
  }
}