.files-input {
  .image-wrapper {
    img {
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .item-wrapper {
    position: relative;
  }

  .remove-link {
    background: rgba(0, 0, 0, 0.46) url("../images/icons/x.svg") no-repeat 50% 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .files-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .images {
    img {
      height: auto;
    }
  }
}