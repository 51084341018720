.check-form {
  position: relative;
  background: #ffffff;

  &__title {
    font-style: italic;
    font-weight: 900;
    font-size: 18px;
    line-height: 27/18*1em;
    text-align: center;
    letter-spacing: 0.03em;
    color: black;
    text-transform: uppercase;
    padding-bottom: 28px;
  }

  &__service-field {
    margin-bottom: 15px;
    select {
      height: 100%;
      border-bottom: 1px solid var(--grey-text-color);
      padding: 10px;
      appearance: auto;
    }
  }

  &__number-field {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    input[type="text"] {
      border: none;
      padding-left: 10px;
      --default-input-height: 40px;
      border-bottom: 1px solid black;
    }

    label {
      font-size: 14px;
      padding-left: 10px;
    }
  }

  &__button {
    background-color: var(--red-color);
    color: white;
    padding: 18px 0;
    width: 100%;
    box-shadow: 0 20px 30px -15px rgba(214, 47, 31, 0.5);
    text-transform: uppercase;
    font-weight: 900;
    font-size: 13px;
    line-height: 17/13*1em;
    letter-spacing: 0.04em;
  }

  &__button-text {
    position: relative;

    &:before {
      z-index: 10;
      left: -25px;
      position: absolute;
      width: 14px;
      height: 15px;
      content: '';
      background: {
        image: url('../svg/reg-icon.svg');
      };
    }
  }

  &__todo-list {
    list-style-type: none;
  }

  &__todo-item {
    font-weight: normal;
    font-size: 14px;
    line-height: 21/14*1em;
    color: #7A7A7A;
    padding-bottom: 22px;
    position: relative;
    padding-left: 30px;

    &:before {
      z-index: 10;
      left: 0;
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      background: {
        image: url('../svg/circle.svg');
      };
    }

    &:after {
      position: absolute;
      content: '';
      top: 6px;
      left: 7px;
      height: 100%;
      border-left: 1px dotted var(--red-color);
    }

    &:last-child {
      padding-bottom: 0;

      &:after {
        height: 0;
      }
    }
  }

  &__service-field {
    margin-top: 14px;
  }

  &__success {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: transform .4s ease-in-out, opacity .4s ease-in-out;
    background-color: white;
  }

  &__success-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__subtitle {
    font-size: 15px;
    line-height: 1.5em;
    color: #7A7A7A;
  }

  &.success {
    &.success_many {
      ._single {
        display: none;
      }
      ._many {
        display: block;
      }
    }

    ._single {
      display: block;
    }

    ._many {
      display: none;
    }

    .check-form {
      &__success {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        pointer-events: auto;
        z-index: 10;
      }
    }
  }
}

@include respond-up('large') {
  .check-form {
    padding: 34px 47px 63px;

    &__success {
      padding: 34px 47px 63px;
    }

    &__icon {
      margin-bottom: 20px;
    }

    &__button {
      margin: 24px 0 35px;
    }
  }
}

@include respond-down('medium') {
  .check-form {
    padding: 12px 12px 20px;

    &__success {
      padding: 20px;
    }

    &__success-box {
      max-width: 250px;
      margin: 0 auto;

      .check-form__title {
        padding-bottom: 15px;
      }
    }

    &__icon {
      margin-bottom: 10px;
    }

    &__title {
      display: none;
    }

    &__button {
      margin: 12px 0 23px;
    }
  }
}