article {
  p {
    line-height: 150%;
    color: var(--grey-light-color);

  }

  strong {
    font-style: italic;
    font-weight: 900;
    line-height: 150%;
    color: black;
  }

  em {
  }

  a {

    &:hover {
    }
  }
}

@include respond-up('large') {
  article {
    p {
      font-size: 17px;
    }

    strong {
      font-size: 18px;

    }

    em {
    }

    a {

      &:hover {
      }
    }
  }
}

@include respond-down('medium') {
  article {
    p {
      font-size: 15px;
    }

    strong {
      font-size: 16px;
    }

    em {
    }

    a {

      &:hover {
      }
    }
  }
}