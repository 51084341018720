.header {
  display: flex;
  justify-content: space-between;

  &__logo-wrapper {

  }

  &__logo {
    z-index: 10;
    position: relative;
  }

  &__timer {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: -120px;
      top: 0;
      width: 500px;
      height: 120px;
    }
  }

  &__inner {
    display: flex;
    background-color: var(--grey-color);
  }

  &__link {
    color: white;
  }

  &__button {
    background-color: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 133%;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid var(--red-color);
    color: var(--red-color);
    border-radius: 54px;
    transition: var(--default-transition);

    &:hover{
      background-color: var(--red-color);
      color: #fff;
    }
  }

}

@include respond-up('large') {
  .header {
    &__inner{
      position: relative;
      padding: 18px 15px 12px 30px;

      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: -60px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 104px 60px 0 0;
        border-color: #19222e transparent transparent transparent;
      }
    }

    &__logo-wrapper {
      width: 183px;
      height: 74px;
      margin-right: 28px;
    }


    &__timer {
      padding-top: 10px;
      padding-left: 33px;
    }

    &__link-block {
      padding: 32px 74px;
      z-index: 10;
      white-space: nowrap;
    }

    &__link-wrap{
      padding: 0 14px;
    }

    &__link {
      padding-bottom: 5px;
      font-size: 15px;
      line-height: 18px;
      border: 1px solid transparent;
      transition: ease border .3s;

      &:hover{
        border-bottom: 1px solid #fff;
      }
    }

    &__button {
      margin-left: 30px;
      padding: 12px 24px;
    }

    &__mobile-hamburger {
      display: none;
    }

    &__expand-block {
      display: none;
    }
    &__timer-mobile {
      display: none;
    }
  }
}
@include respond('medium'){
  .header{
    position: relative;

    &__logo-wrapper{
      margin-right: 12px;
    }

    &__logo-link{
      svg{
        width: 171px;
      }
    }

    &__inner{
      background: var(--dark-color-opacity);
      position: relative;
      align-items: center;
      padding: 5px 20px 12px 36px;

      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: -45px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 92px 45px 0 0;
        border-color: var(--dark-color-opacity) transparent transparent transparent;
      }
    }

    &__timer-mobile {
      display: none;
    }
  }
}

@include respond-down('medium'){
  .header{
    &__logo {
      width: 125px;
      height: 74px;
    }

    &__mobile-hamburger {
      padding: 20px;
    }

    &__expand-block {
      padding: 32px 0;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      opacity: 0;
      width: 100%;
      height: calc((232/375) * 100vw);
      background-color: var(--t);
      transition: var(--default-transition);
      font-weight: 900;
      font-size: 16px;
      line-height: 150%;
      align-items: center;

      &._opened {
        opacity: 1;
        pointer-events: auto;
        z-index: 11;
        width: 100%;
        background-color: white;
      }
    }

    &__link-mobile {
      display: block;
      margin-bottom: 12px;
    }

    &__expand-list {

    }

    &__button {
      padding: 8px 16px;
    }



    &__link-block {
      display: flex;
      align-items: center;
      padding: 0;
    }

    &__link {
      display: none;
    }

    &__button {
      //background-color: rgba(255, 255, 255, 0.1);
      //color: white;
      //display: inline-block;
      //max-height: 40px;
    }
  }
}

@include respond-down('small') {
  .header {
    &__inner{
      padding-top: 10px;
      padding-left: 20px;
      background: transparent;
    }

    &__timer {
      display: none;
    }

    &__logo-link{
      svg{
        width: 143px;
      }
    }

    &__timer-mobile {
      margin: 0 auto 24px;
    }
  }
}