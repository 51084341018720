/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: 20
  ),
  medium: (
    from: 768,
    to: 1199,
    spacer: 30
  ),
  large: (
    from: 1200,
    to: 1920,
    spacer: 40
  ),
  x-large: (
    from: 1921,
    spacer: 50
  )
);

:root {
  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Helvetica Neue Thin", sans-serif;
  --h-font: "Helvetica Neue Thin", sans-serif;

  --t: transparent;

  --border-color: #A8A8A8;
  --dark-text-color: #16171A;
  --gray-text-color: #A5A5A5;
  --grey-color: #19222E;
  --dark-color: #000D1F;
  --grey-color-another: #2D3136;
  --dark-color-opacity: rgba(71, 71, 71, .21);
  --grey-text-light: #B1B1B1;
  --grey-text-color: #2D3136;
  --grey-light-color: #7A7A7A;
  --border-grey: #CBCBCB;
  --red-color: #F26522;
  --dark-red-color: #F26522;
  --alert-color: #b50000;
  --default-transition: all 0.4s;
}
