.hamburger{
  z-index: 50;
  position: relative;
  height: 30px;
  display: block;
  width: 40px;

  &__line{
    position: absolute;
    width: 29px;
    height: 2px;
    background-color: white;
    transform-origin: center;
    transition: {
      duration: .2s;
      timing-function: ease-in-out;
    };

    &_top{
      top: calc(50% - 10px);
      transition-property: top, transform;
    }

    &_middle{
      top: calc(50% - 1px);
      transition-property: opacity;
    }

    &_bottom{
      bottom: calc(50% - 10px);
      transition-property: bottom, transform;
    }
  }

  &._opened{
    .hamburger__line{
      &_top{
        background-color: black;
        top: calc(50% - 1px);
        transform: rotate(45deg);
      }

      &_middle{
        opacity: 0;
      }

      &_bottom{
        background-color: black;
        bottom: calc(50% - 1px);
        transform: rotate(-45deg);
      }
    }
  }
}